import React, { useState, useEffect } from "react";
import { getRatesObject } from "./../../../utils/parse";
import { getUrlParams } from "./../../../utils/utils";
import Layout from "./../../../components/Layout";
import Modifier from "./../../../nav/Admin/Commerces/Modifier";
import { Helmet } from "react-helmet";
import firebase from "gatsby-plugin-firebase";
import { getUser } from "./../../../auth";
import Error403 from "../../../nav/Error403";
import { TailSpin } from "react-loader-spinner";

const HandleUserStates = ({ user, shop }) => {
  if (user === undefined) {
    return (
      <div style={{ textAlign: "center" }}>
        <TailSpin color="#FF8000" height={200} width={200} />
      </div>
    );
  } else if (user && user.subscription.type === "admin") {
    return <Modifier shop={shop} />;
  } else {
    return <Error403 />;
  }
};

const ModifierPage = () => {
  const [user, setUser] = useState(undefined);
  const [shop, setShop] = useState(null);

  useEffect(() => {
    getUser(setUser);
    const id = getUrlParams("id");
    firebase
      .firestore()
      .collection("shops")
      .doc(id)
      .get()
      .then((doc) => {
        setShop({
          id: doc.id,
          name: doc._delegate._document.data.value.mapValue.fields.name
            .stringValue,
          description:
            doc._delegate._document.data.value.mapValue.fields.description
              .stringValue,
          category:
            doc._delegate._document.data.value.mapValue.fields.category
              .stringValue,
          image:
            doc._delegate._document.data.value.mapValue.fields.image
              .stringValue,
          address:
            doc._delegate._document.data.value.mapValue.fields.address
              .stringValue,
          latLng:
            doc._delegate._document.data.value.mapValue.fields.latLng
              .geoPointValue,
          rates: getRatesObject(
            doc._delegate._document.data.value.mapValue.fields.rates.arrayValue
              .values
          ),
          schedule:
            doc._delegate._document.data.value.mapValue.fields.schedule
              .arrayValue.values,
          ourFavoriteIndex: Number(
            doc._delegate._document.data.value.mapValue.fields.ourFavoriteIndex
              .integerValue
          ),
        });
      })
      .catch((error) => {
        //console.log("Error getting document:", error)
      });
  }, []);

  return (
    <Layout user={user}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WYNIT | Modifier le commerce</title>
        <meta
          name="description"
          content="WYNIT, les bons plans marseillais pour les 15-30 ans. Tu pourras ici modifier le commerce."
        />
        <meta
          name="keywords"
          content="réductions,jeunes,admin,commerce,modifier"
        />
        <link
          rel="canonical"
          href="https://wynit.com/admin/commerces/modifier"
        />
      </Helmet>
      <HandleUserStates user={user} shop={shop} />
    </Layout>
  );
};

export default ModifierPage;
