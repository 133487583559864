export const rewriteShopSchedules = schedules => {
  if (schedules === undefined || !schedules) {
    return ["", "", "", "", "", "", ""]
  }
  var idx = 0
  var newSchedules = []
  while (idx < schedules.length) {
    newSchedules.push(schedules[idx].stringValue)
    idx++
  }
  return newSchedules
}
