// extracted by mini-css-extract-plugin
export var ButtonWrapper = "Modifier-module--ButtonWrapper--sLOw5";
export var DescriptionWrapper = "Modifier-module--DescriptionWrapper--YB-A+";
export var FormWrapper = "Modifier-module--FormWrapper--kxOZt";
export var ImageWrapper = "Modifier-module--ImageWrapper--pL2tQ";
export var InputWrapper = "Modifier-module--InputWrapper--ug55A";
export var NoImageWrapper = "Modifier-module--NoImageWrapper--NYBRI";
export var Rounded = "Modifier-module--Rounded--16Hgs";
export var RowWrapper = "Modifier-module--RowWrapper--Xp4SS";
export var Spacer = "Modifier-module--Spacer--iDsRq";
export var Wrapper = "Modifier-module--Wrapper--y58Dh";