export const getRatesObject = rates => {
  var idx = 0
  var newRates = []

  if (rates === undefined || !rates) {
    return newRates
  }
  while (idx < rates.length) {
    newRates.push({
      comment: rates[idx].mapValue.fields.comment.stringValue,
      date: parseInt(rates[idx].mapValue.fields.date.integerValue),
      offerId: rates[idx].mapValue.fields.offerId.stringValue,
      userId: rates[idx].mapValue.fields.userId.stringValue,
      rate: rates[idx].mapValue.fields.rate.stringValue,
    })
    idx++
  }
  return newRates
}
